import {
  ISettingContainerBaseProps,
  TSettingContainerOnSaveReturnType,
} from "src/components/Settings/Setting/SettingContainer"
import {
  SettingNumber,
  TNumberError,
} from "src/components/Settings/Setting/SettingNumber"
import { TMTextFieldProps } from "src/ui/MTextField/MTextField"

export function ParadiseSettingNumber({
  title,
  value,
  min,
  max,
  minRecommended: _minRecommended,
  maxRecommended: _maxRecommended,
  errorHelperText,
  ...props
}: ISettingContainerBaseProps & {
  textFieldProps?: TMTextFieldProps
  onSave(newVal: number): TSettingContainerOnSaveReturnType
  value: number
  min: number
  max: number
  minRecommended?: number
  maxRecommended?: number
  errorHelperText: (type: TNumberError) => string | null
}) {
  return (
    <SettingNumber
      title={title}
      titleProps={{
        type: "nano",
        color: "secondary",
      }}
      displayValueProps={{
        type: "bodyS",
        color: "primary",
      }}
      value={value}
      min={min}
      max={max}
      errorHelperText={errorHelperText}
      {...props}
    />
  )
}
